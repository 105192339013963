/* eslint-disable no-param-reassign */
import { API } from 'aws-amplify';
import * as mutations from '@/graphql/mutations';
import * as queries from '@/graphql/queries';
export default {
  async getDetail(id) {
    return API.graphql({
      query: queries.getStaticPageFAQ,
      variables: { id },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    });
  },
  async getList(variables = {}) {
    return API.graphql({
      query: queries.listStaticPageFAQs,
      variables, 
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    })
  },
  async createUpdate(input) {
    if (!input.id) {
      return API.graphql({
        query: mutations.createStaticPageFAQ,
        variables: { input },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      })
    } 
    delete input.createdAt
    delete input.updatedAt
    delete input.owner
    return API.graphql({
      query: mutations.updateStaticPageFAQ,
      variables: { input },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    })
  },

  async delete(id) {
    return API.graphql({
      query: mutations.deleteStaticPageFAQ,
      variables: { input: { id } },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    });
  },

}
